/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import './theme/color.scss';
button:focus { outline:0; }
button { background-color: inherit; }
/* Ioc 기본 css를 변경 */
.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
  // --background: rgba(0,0,0,0);
}

.range-knob-pressed .range-pin {
  transform: translate3d(0, 7px, 0) scale(1) !important;
}

.searchbar-input-container.sc-ion-searchbar-ios {
  height: 100%;
  .searchbar-input.sc-ion-searchbar-ios {
    padding-inline-start: 12px !important;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
    background-color: $bgGray;
  }
  ion-icon.searchbar-search-icon {
    display: none;
  }
}

/* Shopping-cart의 action-sheet */
.order-action-sheet {
  .action-sheet-group {
    padding-bottom: 6px;
    .action-sheet-title {
      background: inherit;
    }

    button {
      background-color: $gkRed;
      display: inline-block;
      margin: 0 4px 0 10px;
      width: calc(50% - 14px);
      padding: 9px 0;
      border-radius: 8px;
      height: 48px;

      &:last-child {
        margin: 0 10px 0 4px;
      }
      span {
        font-size: 18px;
        height: 30px;
        color: $white;
        font-weight: bold;
      }
    }
  }
}
