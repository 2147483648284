$white: #fff;
$black: #020813;
$lightBlack: rgba(0, 0, 0, 0.87);
$textSub: #73849A;
$borderGray: #E5E8EE;
$bgGray: #F6F7FA;
$bgGray02: #DCDCDC;
$boxShadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px, rgba(0, 0, 0, 0.05) 0px 1px 4px 0px, rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;

// New design guideline
$gkRed: #EA0029;
$bgRed: #FEB0C0;
$gkBlack: #202121;
$stainlessGray: #a2a5a5;
$warmGray: #bdb8b1;
$lightGray: #f5f3f1;
$pureWhite: #ffffff;

$baemin: #2AC1BC;
